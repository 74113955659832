import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, emptyTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Number display
  NumberDisplay();

  // Auto focus on typeahead when open a dropdown
  $('.dropdown').on('shown.bs.dropdown', function () {
    let input = $(this).find('input');
    if (typeof input !== 'undefined') {
      input.focus();
    }
  })

  // Start with sector
  filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');

  // Set industry group using sector
  let setUpIndustryGroupTypeahead = function() {
    let industryGroupUrl = '/companies/attrs/search?attr=industry_group&sector=';
    let industryGroupBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: industryGroupUrl,
        cache: false,
        replace: function () {
          var q = '/companies/attrs/search?attr=industry_group&sector=';
          if ($('#sectors_typeahead').val()) {
            q += encodeURIComponent($('#sectors_typeahead').val());
          }
          return q;
        }
      }
    });
    industryGroupBloodhound.initialize();
    $('.industry-group-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'industry_groups',
      display: 'name',
      limit: 115,
      source: industryGroupBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.value);
          }
        }
      },
    });
  }
  setUpIndustryGroupTypeahead();

  // Set industry using industry group
  let setUpIndustryTypeahead = function() {
    let industryUrl = '/companies/attrs/search?attr=industry&industry_group=';
    let industryBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: industryUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/attrs/search?attr=industry&industry_group=';
          if ($('#industry_groups_typeahead').val()) {
          	q += encodeURIComponent($('#industry_groups_typeahead').val());
          }
        	return q;
        }
    	}
    });
    industryBloodhound.initialize();
    $('.industry-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'industries',
      display: 'name',
      limit: 115,
      source: industryBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.value);
          }
        }
      },
    });
  }
  setUpIndustryTypeahead();


  // Set sub-industry using sector
  let setUpSubIndustryTypeahead = function() {
    let subIndustryUrl = '/companies/attrs/search?attr=sub_industry&industry=';
    let subIndustryBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: subIndustryUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/attrs/search?attr=sub_industry&industry=';
          if ($('#industries_typeahead').val()) {
          	q += encodeURIComponent($('#industries_typeahead').val());
          }
        	return q;
        }
    	}
    });
    subIndustryBloodhound.initialize();
    $('.sub-industry-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'sub_industries',
      display: 'name',
      limit: 115,
      source: subIndustryBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.value);
          }
        }
      },
    });
  }
  setUpSubIndustryTypeahead();


  // Set SASB sector using sector
  let setUpSasbTypeahead = function() {
    let sasbUrl = '/companies/sasb_industries/search?sub_industry=';
    let sasbBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: sasbUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/sasb_industries/search?sub_industry=';
          if ($('#sub_industries_typeahead').val()) {
          	q += encodeURIComponent($('#sub_industries_typeahead').val());
          }
        	return q;
        }
    	}
    });
    sasbBloodhound.initialize();
    $('.sasb-industry-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'sasb_industries',
      display: 'industry',
      limit: 314,
      source: sasbBloodhound,
      templates: {
        suggestion(el) {
          if (el.industry === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.industry);
          }
        }
      },
    });
  }
  setUpSasbTypeahead();


  // On blur
  $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    ev.preventDefault();
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    hiddenField.val(suggestion.value);
    if (typeof suggestion.industry !== 'undefined') {
      $(this).typeahead('val', suggestion.industry);
    } else {
      $(this).typeahead('val', suggestion.value);
    }
    $(this).blur();
    if ($(this).hasClass('sector-filter')) {
    	$('.no-sector').addClass('d-none');
    	$('#industry_groups_typeahead').typeahead('destroy');
      $('#industry_groups_typeahead').val('');
      $('#industry_groups_typeahead').removeClass('d-none');
      setUpIndustryGroupTypeahead();
      $('#industries_typeahead').typeahead('destroy');
      $('#industries_typeahead').val('');
      setUpIndustryTypeahead();
      $('#sub_industries_typeahead').typeahead('destroy');
      $('#sub_industries_typeahead').val('');
      setUpSubIndustryTypeahead();
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      setUpSasbTypeahead();
    } else if ($(this).hasClass('industry-group-filter')) {
      $('.no-industry-group').addClass('d-none');
      $('#industries_typeahead').typeahead('destroy');
      $('#industries_typeahead').val('');
      $('#industries_typeahead').removeClass('d-none');
      setUpIndustryTypeahead();
      $('#sub_industries_typeahead').typeahead('destroy');
      $('#sub_industries_typeahead').val('');
      setUpSubIndustryTypeahead();
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      setUpSasbTypeahead();
    } else if ($(this).hasClass('industry-filter')) {
    	$('.no-industry').addClass('d-none');
      $('#sub_industries_typeahead').typeahead('destroy');
      $('#sub_industries_typeahead').val('');
      $('#sub_industries_typeahead').removeClass('d-none');
      setUpSubIndustryTypeahead();
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      setUpSasbTypeahead();
    } else if ($(this).hasClass('sub-industry-filter')) {
    	$('.no-sub-industry').addClass('d-none');
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      $('#sasb_industries_typeahead').removeClass('d-none');
      setUpSasbTypeahead();
    }
  });



}