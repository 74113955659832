import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import Swal from 'sweetalert2';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class ControversiesIndex {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

    // Select all indicators
    $('.select-all-controversies').click(function() {
      $('#controversies_table tr .checkbox').prop('checked', this.checked);
    });

    // Init filter typeaheads
    filterTypeahead('.company-filter', 'companies', '/companies/search?', 172, 'logo');
    filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');
    filterTypeahead('.industry-filter', 'industries', '/companies/attrs/search?attr=industry&', 115, 'generic');
    filterTypeahead('.sub-industry-filter', 'sub_industries', '/companies/attrs/search?attr=sub_industry&', 500, 'generic');
    filterTypeahead('.controversy-source-filter', 'sectors', '/controversies/sources/search?', 210, 'generic');

    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val;
      if (typeof suggestion.name !== 'undefined') {
        val = suggestion.value;
      } else if (typeof suggestion.value !== 'undefined') {
        val = suggestion.value;
      } else {
        val = suggestion;
      }

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      let arr = [];
      let currentAttr = $('#controversies_table').attr('data-' + attribute);
      if (typeof currentAttr !== "undefined") {arr.push(currentAttr);}
      arr.push(val);
      $('#controversies_table').attr('data-' + attribute, arr);

      // Reload
      ReloadWithFilters('controversies_table');

    });

    // On typeahead select, create a filter
    $('#controversy_sources_typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val = suggestion.value;

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      $('#controversies_table').attr('data-' + attribute, val);

      // Reload
      ReloadWithFilters('controversies_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-controversies").length > 0 )  {
      $('.update-selected-controversies').click(function() {
        $('#controversies_table').css('opacity', 0.25);
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyContIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let contId = $(this).closest('tr').attr('data-record-id');
          companyContIds.push(contId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['controversy_ids'] = companyContIds;
        $.ajax({
          type: 'PATCH',
          url: '/controversies/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            let controversyIds = responseText.controversy_ids;
            let attributeValue = responseText.attribute_value;
            $.each(controversyIds, function(i,h) { 
              let row = $('#controversies_table').find("tr[data-record-id=" + h + "]");
              if (responseText.attribute === "status") {
                let ratingClass;
                let ratingText;
                if (attributeValue === "production_ready") {
                  ratingClass = "text-a-rating";
                  ratingText = "Production ready";
                } else if (attributeValue === "final_review_completed") {
                  ratingClass = "text-b-rating";
                  ratingText = "Final review completed";
                } else if (attributeValue === "analyst_completed") {
                  ratingClass = "text-c-rating";
                  ratingText = "Analyst completed";
                } else if (attributeValue === "returned_for_sm_review") {
                  ratingClass = "text-d-rating";
                  ratingText = "Returned for SM review";
                } else if (attributeValue === "needs_ethos_review") {
                  ratingClass = "text-f-rating";
                  ratingText = "Needs Ethos review";
                } else {
                  ratingClass = "text-f-rating";
                  ratingText = "In queue";
                }
                row.find('.review-status').replaceWith("<span class=\'font-weight-bold review-status " + ratingClass + "\'>" + ratingText + "</span>");

              } else if (responseText.attribute === "score") {
                let colInd = $('th[data-db-col=\'score\']').index();
                row.find("td").eq(colInd).empty().append(attributeValue);
              }
            })
            $('#controversies_table').css('opacity', 1);
            ToastCustom('Updated', 'Controversies updated', 5000);
          },
        });
      })
    }

    // Delete on select
    if ( $(".delete-selected-controversies").length > 0 )  {
      $('.delete-selected-controversies').click(function() {

        return Swal.fire({
          title: "Are you sure?",
          text: "Confirm you would like to delete all selected controversies",
          animation: false,
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light border',
            popup: 'animated fadeIn faster'
          }
        }).then((result) => {
          if (result.value) {
            let controversyIds = [];
            $.each( $('tr .checkbox:checkbox:checked'), function() {
              let controversyId = $(this).closest('tr').attr('data-record-id');
              controversyIds.push(controversyId);
            })
            let params = {};
            params['controversy_ids'] = controversyIds;
            $.ajax({
              type: 'DELETE',
              url: '/controversies/bulk_delete?' + $.param(params),
              dataType: 'application/json',
              complete(result) { 
                let responseText = JSON.parse(result.responseText);
                $.each(controversyIds, function(i,h) { 
                  $('#controversies_table').find("tr[data-record-id=" + h + "]").remove();
                })
                ToastCustom('Removed', 'Controversies removed', 5000);
                $('.select-all-controversies').prop('checked', false);
              },
            });
          }
        });
      })
    }

    // Combine on select
    if ( $(".combine-selected-controversies").length > 0 )  {
      $('.combine-selected-controversies').click(function() {

        return Swal.fire({
          title: "Are you sure?",
          text: "Confirm you would like to combine all selected controversies",
          animation: false,
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light border',
            popup: 'animated fadeIn faster'
          }
        }).then((result) => {
          if (result.value) {
            let controversyIds = [];
            $.each( $('tr .checkbox:checkbox:checked'), function() {
              let controversyId = $(this).closest('tr').attr('data-record-id');
              controversyIds.push(controversyId);
            })
            let params = {};
            params['controversy_ids'] = controversyIds;
            $.ajax({
              type: 'PATCH',
              url: '/controversies/combine?' + $.param(params),
              dataType: 'application/json',
              complete(result) { 
                let responseText = JSON.parse(result.responseText);
                $.each(responseText.removed, function(i,h) { 
                  $('#controversies_table').find("tr[data-record-id=" + h + "]").remove();
                })
                ToastCustom('Combined', 'Controversies combined', 5000);
                $('.select-all-controversies').prop('checked', false);
                $('#controversies_table tbody td input.checkbox:checked').prop('checked', false);
              },
            });
          }
        });
      })
    }


  }

}