import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class ScreensIndex {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

    // Select all indicators
    $('.select-all-screens').click(function() {
      $('#screens_table tr .checkbox').prop('checked', this.checked);
    });

    // Init filter typeaheads
    filterTypeahead('.category-filter', 'categories', '/metrics/attrs/search?attr=category&', 83, 'generic');
    filterTypeahead('.source-name-filter', 'source_name', '/metrics/attrs/search?attr=impact_sources.name&', 115, 'generic');
    filterTypeahead('.company-filter', 'companies', '/companies/search?', 172, 'logo');
    filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');
    filterTypeahead('.industry-filter', 'industries', '/companies/attrs/search?attr=industry&', 115, 'generic');
    filterTypeahead('.sub-industry-filter', 'sub_industries', '/companies/attrs/search?attr=sub_industry&', 500, 'generic');
    filterTypeahead('.region-filter', 'regions', '/companies/attrs/search?attr=region&', 18, 'generic');
    filterTypeahead('.market-cap-filter', 'market_caps', '/companies/attrs/search?attr=market_cap&', 200, 'generic');
    filterTypeahead('.screen-filter', 'name', '/screens/search?', 322, 'generic');

    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val;
      if (typeof suggestion.name !== 'undefined') {
        val = suggestion.value;
      } else if (typeof suggestion.value !== 'undefined') {
        val = suggestion.value;
      } else {
        val = suggestion;
      }

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      let arr = [];
      let currentAttr = $('#screens_table').attr('data-' + attribute);
      if (typeof currentAttr !== "undefined") {arr.push(currentAttr);}
      arr.push(val);
      $('#screens_table').attr('data-' + attribute, arr);

      // Reload
      ReloadWithFilters('screens_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-screens").length > 0 )  {
      $('.update-selected-screens').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let companyScreenIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let screenId = $(this).closest('tr').attr('data-record-id');
          companyScreenIds.push(screenId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['screen_ids'] = companyScreenIds;
        $.ajax({
          type: 'PATCH',
          url: '/screens/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#screens_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Screens updated', 5000);
            $('.select-all-screens').prop('checked', false);
          },
        });
      })
    }

  }

}