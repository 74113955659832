export default function(tableId = null) {
  let tableEl;
  if (tableId === null) {
    tableEl = $('.admin-table-fixed')
  } else {
    tableEl = $('#' + tableId)
  }

  let url = tableEl.attr('data-url');
  let params = {};
  let sourceId = tableEl.attr('data-source-id');
  let source = tableEl.attr('data-source');
  let companyId = tableEl.attr('data-company-id');
  let impactScreenId = tableEl.attr('data-impact-screen-id');
  let analystId = tableEl.attr('data-analyst-id');
  let frequency = tableEl.attr('data-frequency');
  let date = tableEl.attr('data-date');
  let reviewerId = tableEl.attr('data-reviewer-id');
  let status = tableEl.attr('data-starting-status');
  let priorityLevel = tableEl.attr('data-priority-level');
  let priority = tableEl.attr('data-priority');
  let collectionType = tableEl.attr('data-collection-type');
  let sector = tableEl.attr('data-sector');
  let industry = tableEl.attr('data-industry');
  let peerGroup = tableEl.attr('data-sub-industry');
  let region = tableEl.attr('data-region');
  let marketCap = tableEl.attr('data-market-cap');
  let category = tableEl.attr('data-category');
  let pass = tableEl.attr('data-pass-filter');
  let peerValue = tableEl.attr('data-peer-value');
  let dataPresent = tableEl.attr('data-data-present');
  let calculated = tableEl.attr('data-calculated');
  let metricId = tableEl.attr('data-metric-id');
  let nameShort = tableEl.attr('data-name-short');
  let name = tableEl.attr('data-name');
  let tab = tableEl.attr('data-tab');
  let fund_family = tableEl.attr('data-fund-family');
  let fund_type = tableEl.attr('data-fund-type');
  let approach = tableEl.attr('data-approach');
  let cap = tableEl.attr('data-cap');
  let asset_class = tableEl.attr('data-asset-class');
  let country = tableEl.attr('data-country');
  let geography = tableEl.attr('data-geography');

  if (typeof tab === 'undefined') {
    tab = $('a.nav-link.active[role=\'tab\']').attr('data-tab');
    if (typeof tab !== 'undefined') {$('#metric_scores_table').attr('data-tab', tab);}
  }

  if (typeof companyId !== "undefined") {params['company_id'] = companyId;}
  if (typeof sourceId !== "undefined") {params['source_id'] = sourceId;}
  if (typeof source !== "undefined") {params['source'] = source;}
  if (typeof impactScreenId !== "undefined") {params['impact_screen_id'] = impactScreenId;}
  if (typeof analystId !== "undefined") {params['analyst_id'] = analystId;}
  if (typeof frequency !== "undefined") {params['frequency'] = frequency;}
  if (typeof date !== "undefined") {params['date'] = date;}
  if (typeof reviewerId !== "undefined") {params['reviewer_id'] = reviewerId;}
  if (typeof status !== "undefined") {params['status'] = status;}
  if (typeof collectionType !== "undefined") {params['collection_type'] = collectionType;}
  if (typeof priorityLevel !== "undefined") {params['priority_level'] = priorityLevel;}
  if (typeof priority !== "undefined") {params['priority'] = priority;}
  if (typeof sector !== "undefined") {params['sector'] = sector;}
  if (typeof industry !== "undefined") {params['industry'] = industry;}
  if (typeof peerGroup !== "undefined") {params['sub_industry'] = peerGroup;}
  if (typeof pass !== "undefined") {params['pass'] = pass;}
  if (typeof region !== "undefined") {params['region'] = region;}
  if (typeof marketCap !== "undefined") {params['market_cap'] = marketCap;}
  if (typeof peerValue !== "undefined") {params['peer_value'] = peerValue;}
  if (typeof dataPresent !== "undefined") {params['data_present'] = dataPresent;}
  if (typeof calculated !== "undefined") {params['calculated'] = calculated;}
  if (typeof metricId !== "undefined") {params['metric_id'] = metricId;}
  if (typeof nameShort !== "undefined") {params['name_short'] = nameShort;}
  if (typeof name !== "undefined") {params['name'] = name;}
  if (typeof tab !== "undefined") {params['tab'] = tab;}
  if (typeof fund_family !== "undefined") {params['fund_family'] = fund_family;}
  if (typeof fund_type !== "undefined") {params['fund_type'] = fund_type;}
  if (typeof category !== "undefined") {params['category'] = category;}
  if (typeof approach !== "undefined") {params['approach'] = approach;}
  if (typeof cap !== "undefined") {params['cap'] = cap;}
  if (typeof asset_class !== "undefined") {params['asset_class'] = asset_class;}
  if (typeof country !== "undefined") {params['country'] = country;}
  if (typeof geography !== "undefined") {params['geography'] = geography;}
  Turbolinks.visit(url + '?' + $.param(params));
}