// Build url for Api calls to get data for a table
export default function(tableId) {

  // Set element from tableId
  const el = $('#' + tableId);

  // Set params based on filter attributes
  let params = {};
  const fromDate = el.attr('data-api-from-date');
  const year = el.attr('data-year');
  const date = el.attr('data-date');
  const collectionType = el.attr('data-collection-type');
  const impactScreenId = el.attr('data-impact-screen-id');
  const scopeId = el.attr('data-api-scope-id');
  const scopeType = el.attr('data-api-scope-type');
  const controversyCategoryId = el.attr('data-controversy-category-id');
  const analystMetricScoresId = el.attr('data-metric-scores-analyst-id');
  const analystScreensId = el.attr('data-screens-analyst-id');
  const frequency = el.attr('data-frequency');
  const analystGhgId = el.attr('data-ghg-analyst-id');
  const analystId = el.attr('data-analyst-id');
  const analystReviewId = el.attr('data-analyst-review-id');
  const reviewerId = el.attr('data-reviewer-id');
  const userId = el.attr('data-user-id');
  const sourceId = el.attr('data-source-id');
  const recordCount = el.attr('data-api-record-count');
  const excludedStatuses = el.attr('data-excluded-statuses');
  const updatedAt = el.attr('data-updated-at');
  const createdAt = el.attr('data-created-at');
  const maxScore = el.attr('data-max-score');
  const companyId = el.attr('data-company-id');
  const companyIds = el.attr('data-api-company-ids');
  const fundIds = el.attr('data-api-fund-ids');
  const slug = el.attr('data-slug');
  const statusType = el.attr('data-status-type');
  const status = el.attr('data-status');
  const priorityLevel = el.attr('data-priority-level');
  const priority = el.attr('data-priority');
  const country = el.attr('data-country');
  const source = el.attr('data-source');
  const sector = el.attr('data-sector');
  const industry = el.attr('data-industry');
  const peerGroup = el.attr('data-sub-industry');
  const pass = el.attr('data-pass-filter');
  const marketCap = el.attr('data-market-cap');
  const region = el.attr('data-region');
  const category = el.attr('data-category');
  const outlier = el.attr('data-outlier');
  const peerValue = el.attr('data-peer-value');
  const dataPresent = el.attr('data-data-present');
  const calculated = el.attr('data-calculated');
  const metricId = el.attr('data-metric-id');
  const nameShort = el.attr('data-name-short');
  const name = el.attr('data-name');
  const fund_family = el.attr('data-fund-family');
  const fund_type = el.attr('data-fund-type');
  const approach = el.attr('data-approach');
  const cap = el.attr('data-cap');
  const asset_class = el.attr('data-asset-class');
  const geography = el.attr('data-geography');

  // Set formula id
  let formulaId = $('#formula_filter').attr('data-api-formula-id');
  if (typeof formulaId === 'undefined') {formulaId = el.attr('data-api-formula-id');}

  // Add to params if exist as data attrs on table
  if (typeof fromDate !== 'undefined') { params['from_date'] = fromDate; }
  if (typeof collectionType !== 'undefined') { params['collection_type'] = collectionType; }
  if (typeof year !== 'undefined') { params['year'] = year; }
  if (typeof date !== 'undefined') { params['date'] = date; }
  if (typeof impactScreenId !== 'undefined') { params['impact_screen_id'] = impactScreenId; }
  if (typeof analystId !== 'undefined') { params['analyst_id'] = analystId; }
  if (typeof reviewerId !== 'undefined') { params['reviewer_id'] = reviewerId; }
  if (typeof userId !== 'undefined') { params['user_id'] = userId; }
  if (typeof scopeId !== 'undefined') { params['scope_id'] = scopeId; }
  if (typeof scopeType !== 'undefined') { params['scope_type'] = scopeType; }
  if (typeof recordCount !== 'undefined') { params['length'] = recordCount; }
  if (typeof source !== 'undefined') { params['source'] = source; }
  if (typeof sourceId !== 'undefined') { params['source_id'] = sourceId; }
  if (typeof formulaId !== 'undefined') { params['formula_id'] = formulaId; }
  if (typeof companyId !== 'undefined') { params['company_id'] = companyId; }
  if (typeof frequency !== 'undefined') { params['frequency'] = frequency; }  
  if (typeof excludedStatuses !== 'undefined') { params['excluded_statuses'] = excludedStatuses; }  
  if (typeof controversyCategoryId !== 'undefined') { params['controversy_category_id'] = controversyCategoryId; }  
  if (typeof companyIds !== 'undefined') { params['company_ids'] = companyIds; }
  if (typeof fundIds !== 'undefined') { params['fund_ids'] = fundIds; }
  if (typeof status !== 'undefined') { params['status'] = status; }
  if (typeof statusType !== 'undefined') { params['status_type'] = statusType; }
  if (typeof priorityLevel !== 'undefined') { params['priority_level'] = priorityLevel; }
  if (typeof priority !== 'undefined') { params['priority'] = priority; }
  if (typeof maxScore !== 'undefined') { params['max_score'] = maxScore; }
  if (typeof updatedAt !== 'undefined') { params['updated_at'] = updatedAt; }
  if (typeof createdAt !== 'undefined') { params['created_at'] = createdAt; }
  if (typeof country !== 'undefined') { params['country'] = country; }
  if (typeof sector !== 'undefined') { params['sector'] = sector; }
  if (typeof pass !== 'undefined') { params['pass'] = pass; }
  if (typeof industry !== 'undefined') { params['industry'] = industry; }
  if (typeof peerGroup !== 'undefined') { params['sub_industry'] = peerGroup; }
  if (typeof marketCap !== 'undefined') { params['market_cap'] = marketCap; }
  if (typeof region !== 'undefined') { params['region'] = region; }
  if (typeof category !== 'undefined') { params['category'] = category; }
  if (typeof outlier !== 'undefined') { params['outlier'] = outlier; }
  if (typeof peerValue !== 'undefined') { params['peer_value'] = peerValue; }
  if (typeof dataPresent !== 'undefined') { params['data_present'] = dataPresent; }
  if (typeof calculated !== 'undefined') { params['calculated'] = calculated; }
  if (typeof metricId !== 'undefined') { params['metric_id'] = metricId; }
  if (typeof nameShort !== 'undefined') { params['name_short'] = nameShort; }
  if (typeof name !== 'undefined') { params['name'] = name; }
  if (typeof fund_family !== "undefined") {params['fund_family'] = fund_family;}
  if (typeof fund_type !== "undefined") {params['fund_type'] = fund_type;}
  if (typeof approach !== "undefined") {params['approach'] = approach;}
  if (typeof cap !== "undefined") {params['cap'] = cap;}
  if (typeof asset_class !== "undefined") {params['asset_class'] = asset_class;}
  if (typeof country !== "undefined") {params['country'] = country;}
  if (typeof geography !== "undefined") {params['geography'] = geography;}

  // Set url based on chart data attr
  // Include Api prefix
  let url = el.attr('data-api-url');

  // Return url with params
  return url + '?' + $.param(params);

};
