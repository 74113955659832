import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ReloadWithFilters from 'src/javascripts/components/utilities/ReloadWithFilters';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';

export default class MetricScoresIndex {

  constructor() {}

  render() {

    // Number display
    NumberDisplay();

    // Select all metric scores
    $('.select-all-metric-scores').click(function() {
      $('#metric_scores_table tr .checkbox').prop('checked', this.checked);
    });

    // Analysts typeahead
    if ( $(".users-typeahead").length > 0 )  {
      let analystsTypeahead = new UsersTypeahead('.users-typeahead');
      $.when(analystsTypeahead.initializeBloodhound() )
       .then( bloodhound => analystsTypeahead.render(bloodhound) )
       .then( analystsTypeahead.setUp('.users-typeahead'));
    }

    // Init filter typeaheads
    filterTypeahead('.category-filter', 'categories', '/metrics/attrs/search?attr=category&', 83, 'generic');
    filterTypeahead('.source-name-filter', 'source_name', '/metrics/attrs/search?attr=impact_sources.name&', 115, 'generic');
    filterTypeahead('.company-filter', 'companies', '/companies/search?', 172, 'logo');
    filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');
    filterTypeahead('.industry-filter', 'industries', '/companies/attrs/search?attr=industry&', 115, 'generic');
    filterTypeahead('.sub-industry-filter', 'sub_industries', '/companies/attrs/search?attr=sub_industry&', 500, 'generic');
    filterTypeahead('.region-filter', 'regions', '/companies/attrs/search?attr=region&', 18, 'generic');
    filterTypeahead('.market-cap-filter', 'market_caps', '/companies/attrs/search?attr=market_cap&', 200, 'generic');
    filterTypeahead('.metric-filter', 'name_short', '/metrics/search?', 674, 'generic');

    // On typeahead select, create a filter
    $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Hide tooltip
      $('.tooltip').tooltip('hide');

      // Set value to symbol if a fund; otherwise value
      let val;
      if (typeof suggestion.name !== 'undefined') {
        val = suggestion.value;
      } else if (typeof suggestion.value !== 'undefined') {
        val = suggestion.value;
      } else {
        val = suggestion;
      }

      // Update attr in table
      let attribute = $(this).attr('data-attribute');
      $('#metric_scores_table').attr('data-' + attribute, val);

      // Reload
      ReloadWithFilters('metric_scores_table');

    });

    // Auto focus on typeahead when open a dropdown
    $('.dropdown').on('shown.bs.dropdown', function () {
      let input = $(this).find('input');
      if (typeof input !== 'undefined') {
        input.focus();
      }
    })

    // Assign on select
    if ( $(".update-selected-metric-scores").length > 0 )  {
      $('.update-selected-metric-scores').click(function() {
        let attribute = $(this).attr('data-attribute');
        let attributeValue = $(this).attr('data-attribute-value');
        let metricScoreIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let metricScoreId = $(this).closest('tr').attr('data-record-id');
          metricScoreIds.push(metricScoreId);
        })
        let params = {};
        params['attribute'] = attribute;
        params['attribute_value'] = attributeValue;
        params['metric_score_ids'] = metricScoreIds;
        $.ajax({
          type: 'PATCH',
          url: '/metric_scores/bulk_assign?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Updated', 'Metric scores updated', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }

    // Delete on select
    if ( $(".delete-selected-metric-scores").length > 0 )  {
      $('.delete-selected-metric-scores').click(function() {
        let metricScoreIds = [];
        $.each( $('tr .checkbox:checkbox:checked'), function() {
          let metricScoreId = $(this).closest('tr').attr('data-record-id');
          metricScoreIds.push(metricScoreId);
        })
        let params = {};
        params['metric_score_ids'] = metricScoreIds;
        $.ajax({
          type: 'DELETE',
          url: '/metric_scores/bulk_delete?' + $.param(params),
          dataType: 'application/json',
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('#metric_scores_table').DataTable().ajax.reload();
            ToastCustom('Removed', 'Metric scores removed', 5000);
            $('.select-all-metric-scores').prop('checked', false);
          },
        });
      })
    }


  }

}